<template>
  <div class="refund-list">

    <en-table-layout :tableData="pageData.data" :loading="loading">
      <template slot="header" v-if="self_type_bool">
        <el-tabs type="card" @tab-click="setType">
          <el-tab-pane label="平台退款订单" />
          <el-tab-pane label="自有商品退款订单" v-if="hasOwnGoods" />
        </el-tabs>
      </template>

      <template slot="toolbar">
        <el-form-item label="售后状态" class="col-auto">
          <el-select style="width: 80px;" size="medium" v-model="advancedForm.refund_status" placeholder="请选择"
            clearable>
            <el-option label="全部" value=""></el-option>
            <el-option label="申请中" value="APPLY"></el-option>
            <el-option label="待寄回" value="WAIT_SEND_BACK"></el-option>
            <el-option label="已寄回" value="SEND_BACK"></el-option>
            <el-option label="已完成" value="COMPLETED"></el-option>
            <el-option label="已拒绝" value="REFUSE"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="售后类型" class="col-auto">
          <el-select style="width: 120px;" size="medium" v-model="advancedForm.refuse_type" placeholder="请选择" clearable>
            <el-option label="全部" value=""></el-option>
            <el-option label="仅退款" value="RETURN_MONEY"></el-option>
            <el-option label="商家退款" value="SHOP_REFUND"></el-option>
            <el-option label="退款退货" value="RETURN_GOODS"></el-option>
            <el-option label="换货" value="EXCHANGE_GOODS"></el-option>
            <el-option label="维修" value="REPAIR_GOODS"></el-option>
            <el-option label="换新" value="RETURN_REPLACE_GOODS"></el-option>
            <el-option label="更换商品" value="REPLACE_GOODS"></el-option>
            <el-option label="售后补发" value="AFTER_REISSUE"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="来源应用" class="col-auto" v-if="$store.getters.shopInfo.shop_type == 3">
          <el-select style="width: 100px" v-model="advancedForm.mall_type" size="small" placeholder="请选择" clearable>
            <el-option v-for="item in mallTypeList" :key="item.value" :label="item.name" :value="item.value" />
          </el-select>
        </el-form-item>
        <el-form-item label="关键词" class="col-auto">
          <el-input style="width: 320px" v-model.trim="advancedForm.searchText" size="medium" placeholder="请输入关键字进行搜索"
            clearable>
            <el-select slot="prepend" style="width: 120px" size="medium" v-model="advancedForm.keyWord"
              placeholder="请选择" clearable>
              <el-option label="售后单号" value="sn"></el-option>
              <el-option label="订单编号" value="trade_sn"></el-option>
              <el-option label="下单账号" value="mobile_account"></el-option>
              <el-option label="商品名称" value="goods_name"></el-option>
              <!-- <el-option label="所属企业" value="enterprise_name"></el-option> -->
            </el-select>
          </el-input>
        </el-form-item>

        <div class="col-auto">
          <el-button @click="advancedSearchEvent" size="small" type="primary">
            搜索
          </el-button>
        </div>

        <div class="col"></div>

        <div class="col-auto">
          <el-button @click="submitImport" :loading="importLoading" size="small" type="primary">
            导出退款订单
          </el-button>
        </div>

        <!-- <div class="box">
          <span>申请时间：</span>
          <el-date-picker
            style="width: 290px"
            v-model="advancedForm.refund_time_range"
            type="daterange"
            align="center"
            size="medium"
            :editable="false"
            unlink-panels
            clearable
            range-separator="-"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>
        </div> -->
        <!-- <div class="box">
          <span>供应商：</span>
          <el-input
            style="width:200px"
            v-model="advancedForm.seller_name"
            placeholder="请输入供应商名称"
           size="medium"
            clearable
          ></el-input>
        </div>-->
        <!-- <div class="box">
          <span>订单编号：</span>
          <el-input
            style="width: 180px"
            v-model="advancedForm.order_sn"
            size="medium"
            placeholder="请输入订单编号"
            clearable
          ></el-input>
        </div> -->
        <!-- <div class="box">
          <span>退款退货单号：</span>
          <el-input
            style="width: 180px"
            v-model="advancedForm.sn"
            size="medium"
            placeholder="请输入退款退货单号"
            clearable
          ></el-input>
        </div> -->
      </template>

      <template slot="table-columns">
        <el-table-column label="图片" width="76" fixed="left" class-name="goods-cover-wrapper">>
          <template slot-scope="{row}">
            <el-popover placement="right" trigger="hover">
              <img :src="row.goods_image" alt="" style="width: 250px; height: 250px;">

              <img :src="row.goods_image" class="goods-cover" slot="reference" alt="" />
            </el-popover>
          </template>
        </el-table-column>

        <el-table-column label="商品名称" min-width="200" :show-overflow-tooltip="true" fixed="left">
          <template slot-scope="{row}">
            {{ row.goods_name ? row.goods_name.replaceAll('amp;', '').replaceAll(' ', '&nbsp;') : '' }}
          </template>
        </el-table-column>

        <el-table-column prop="sn" label="售后单号" width="140" />
        <!--订单号-->
        <el-table-column label="订单编号" width="180">
          <template slot-scope="scope">
            <div v-if="shop_type === 2">
              {{ scope.row.order_sn }}
            </div>
            <div v-else>
              {{ scope.row.trade_sn }}
            </div>
          </template>
        </el-table-column>
        <!--会员名称-->
        <el-table-column label="下单账号" prop="member_name" width="150" class-name="font-family-tttgb">
          <template slot-scope="{row}">
            <div v-if="row.mobile && row.login_account">
              <p>{{ row.mobile }}</p>
              {{ row.login_account }}
            </div>
            <div v-else>
              {{ row.mobile }}
              {{ row.login_account }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="来源应用" align="center" width="200">
          <template slot-scope="{row}">
            <span v-if="row.mall_type == 2">现金商城</span>
            <span v-if="row.mall_type == 3">套餐卡商城</span>
            <span v-if="row.mall_type == 4">AI智能套餐卡商城</span>
            <span v-if="row.mall_type == 5">双选商城</span>
            <span v-if="row.mall_type == 7">一件代发商城</span>
            <span v-if="row.mall_type == 6">小程序商城</span>
          </template>
        </el-table-column>
        <!--所属企业-->
        <el-table-column label="所属站点" min-width="120" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{ scope.row.shop_name }}
          </template>
        </el-table-column>
        <!--创建时间-->
        <el-table-column label="申请时间" width="150">
          <template slot-scope="scope">
            {{ scope.row.create_time | unixToDate }}
          </template>
        </el-table-column>
        <!--类型-->
        <el-table-column prop="refuse_type_text" label="类型" width="100px" fixed="right" />
        <!--状态-->
        <el-table-column prop="refund_status_text" label="状态" width="100px" fixed="right">
          <template slot-scope="scope">
            <span>{{ scope.row.refund_status_text }}</span>
            <el-tooltip v-if="scope.row.refund_status_text == '待补发'" class="item" effect="dark"
              content="待补发是指供应商已经售后审核通过，待重新补发商品的状态。待补发的售后状态归类到【已寄回】的售后状态筛选项中" placement="top-end">
              <i style="margin-left: 4px;" class="el-icon-warning-outline"></i>
            </el-tooltip>
            <div class="refund-fail-reason" v-if="scope.row.refund_status === 'REFUNDFAIL'"
              @click="showRefundFailReason(scope.row)">
              (退款失败原因)
            </div>
            <div v-if="scope.row.refund_reason === '线下售后'">
              <span style="color: red;">(京东自动退回)</span>
            </div>
            <div v-if="scope.row.refund_reason === '京东取消订单'">
              <span style="color: red;">(京东取消订单)</span>
            </div>
          </template>
        </el-table-column>
        <!--操作-->
        <el-table-column label="操作" width="200" fixed="right">
          <template slot-scope="scope">
            <template v-if="advancedForm.self_type === 2 || !self_type_bool">
              <div v-if="shop_id !== 66">
                <el-button
                  v-if="shop_type === 1 && scope.row.after_sale_operate_allowable.allow_seller_approval && scope.row.refuse_shop_status === 0"
                  type="primary" size="mini" @click="examineRefund(scope.row)">
                  审核
                </el-button>
                <el-button v-if="shop_type === 2 && scope.row.after_sale_operate_allowable.allow_seller_approval"
                  type="primary" size="mini" @click="examineRefund(scope.row)">
                  审核
                </el-button>
                <el-button v-if="shop_type === 3 && scope.row.after_sale_operate_allowable.allow_seller_approval"
                  type="primary" size="mini" @click="examineRefund(scope.row)">
                  审核
                </el-button>
                <el-button v-if="scope.row.after_sale_operate_allowable.allow_seller_refund
      " type="primary" size="mini" @click="handleOperateRefund(scope.row)">
                  退款
                </el-button>
                <el-button
                  v-if="scope.row.after_sale_operate_allowable.allow_stock_in && (shop_type === 2 || shop_type === 3)"
                  type="primary" size="mini" @click="handleWareHousing(scope.row)">
                  确认收货
                </el-button>
                <el-button v-if="scope.row.after_sale_operate_allowable.allow_seller_ship && shop_type === 2"
                  type="primary" size="mini" @click="deliverGoods(scope.row)">
                  重新发货
                </el-button>
                <el-button v-if="scope.row.after_sale_operate_allowable.allow_seller_ship && shop_type === 3"
                  type="primary" size="mini" @click="deliverGoods(scope.row)">
                  重新发货
                </el-button>
                <!-- v-if="
                    !scope.row.after_sale_operate_allowable.allow_stock_in &&
                    !scope.row.after_sale_operate_allowable
                      .allow_seller_approval &&
                    !scope.row.after_sale_operate_allowable.allow_seller_ship &&
                    !scope.row.after_sale_operate_allowable.allow_seller_refund
                  " -->
                <el-button type="primary" size="mini" @click="handleRefundDetail(scope.row)">
                  查看
                </el-button>
              </div>
              <div v-else>
                <el-button
                  v-if="shop_type === 2 && scope.row.after_sale_operate_allowable.allow_seller_approval && scope.row.refuse_type === 'SHOP_REFUND'"
                  type="primary" size="mini" @click="examineRefund(scope.row)">
                  审核
                </el-button>
                <el-button type="primary" size="mini" @click="handleRefundDetail(scope.row)">
                  查看
                </el-button>
              </div>
            </template>
            <template v-else>
              <el-button
                v-if="shop_type === 3 && scope.row.after_sale_check == 0 && [4, 6].includes(scope.row.mall_type) && scope.row.refuse_shop_status == 0"
                type="primary" size="mini" @click="examineRefund(scope.row)">
                审核
              </el-button>
              <el-button type="primary" size="mini" @click="handleRefundDetail(scope.row)">
                查看
              </el-button>
            </template>
          </template>
        </el-table-column>
      </template>
      <!--分页-->
      <el-pagination slot="pagination" @size-change="handlePageSizeChange" @current-change="handlePageCurrentChange"
        :current-page="pageData.page_no" :page-sizes="MixinPageSizes" :layout="MixinTableLayout" background
        :page-size="pageData.page_size" :total="pageData.data_total"></el-pagination>
    </en-table-layout>

    <!--退款、退货审核-->
    <el-dialog title="操作" width="500px" :visible.sync="goodsRefundshow1">
      <el-form :model="refundInfo">
        <el-form-item label="售后单号" label-width="120px">
          {{ refundInfo.sn }}
        </el-form-item>
        <el-form-item label="关联订单" label-width="120px">
          {{ refundInfo.trade_sn }}
        </el-form-item>
        <el-form-item label="申请时间" label-width="120px">
          {{ refundInfo.create_time | unixToDate }}
        </el-form-item>
        <el-form-item label="售后状态" label-width="120px">
          {{ refundInfo.refund_status_text }}
        </el-form-item>
        <el-form-item label="服务类型" label-width="120px">
          {{ refundInfo.refuse_type_text }}
        </el-form-item>
        <el-form-item label="申请原因" label-width="120px">
          {{ refundInfo.refund_reason }}
        </el-form-item>
        <el-form-item label="问题描述" label-width="120px">
          {{ refundInfo.customer_remark }}
        </el-form-item>
        <el-form-item label="图片凭证" label-width="120px">
          <div style="display:flex;flex-wrap: wrap; width: 100%; align-content: flex-start;">
            <div v-for="(item, index) in refund_img" :key="index">
              <el-image v-if="item.indexOf('.mp4') == -1" style="width: 80px; height: 80px" :src="item"
                :preview-src-list="refund_img">
              </el-image>
              <div class="playVideo" @click="playVideo(item)" v-else>
                <img :src="item + '?x-oss-process=video/snapshot,t_10000,m_fast'" alt="">
                <span class="el-icon-video-play"></span>
              </div>
            </div>
            <!-- <el-image v-for="(item, index) in refund_img" :key="index" style="width: 100px; height: 100px" :src="item"
              :preview-src-list="refund_img">
            </el-image> -->
          </div>
        </el-form-item>
        <el-form-item label="退货方式" label-width="120px" v-if="refundInfo.refund_ship_type != 1">
          {{ refundInfo.refund_ship_type ? '快递至第三方卖家' : '上门取件' }}
        </el-form-item>
        <!-- 站点退款金额 -->
        <el-form-item label=" 退款金额" label-width="120px" v-if="shop_type === 1">
          {{ refundInfo.refund_shop_price }}
        </el-form-item>
        <!-- 供应商退款金额 -->
        <el-form-item label="退款金额" label-width="120px" v-if="shop_type === 2">
          {{ refundInfo.refund_supplier_price }}
        </el-form-item>
        <!-- 企业-退款金额 -->
        <el-form-item label="退款金额" label-width="120px" v-if="shop_type === 3 && refundInfo.mall_type != 6">
          {{ refundInfo.refund_enterprise_price }}
        </el-form-item>
        <el-form-item label="审核" label-width="120px">
          <el-radio v-model="refund_type" label="1">通过</el-radio>
          <el-radio v-model="refund_type" label="2">拒绝</el-radio>
        </el-form-item>
        <el-form-item label="拒绝原因" label-width="120px" v-if="refund_type === '2'">
          <el-input type="textarea" maxlength="200" :autosize="{ minRows: 12, maxRows: 12 }" v-model="refund_reason"
            placeholder="拒绝原因最多不超200字。"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="goodsRefundshow1 = false">取 消</el-button>
        <el-button type="primary" @click="handleRefund1(refundInfo.sn)">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 暂时废弃 -->
    <el-dialog :title="typeTitle" :visible.sync="goodsRefundshow" width="700px">
      <div class="refund-info">
        <!--订单信息-->
        <div class="order-info">
          <h4 style="margin: 0">订单信息</h4>
          <div class="order-info-item">
            <span>{{ currentType }}单号:</span>
            <span>{{ refundInfo.sn }}</span>
          </div>
          <div class="order-info-item">
            <span>关联订单:</span>
            <span>{{ refundInfo.order_sn }}</span>
          </div>
          <div class="order-info-item" v-if="type !== 'goods1'">
            <span>申请{{ currentType }}金额:</span>
            <span>{{ refundMoney1 | unitPrice('¥') }}</span>
            <span v-if="refundPoint">+ {{ refundPoint }}积分</span>
          </div>
          <div class="order-info-item">
            <span>{{ currentType }}人:</span>
            <span>{{ refundInfo.member_name }}</span>
          </div>
          <div class="order-info-item">
            <span>{{ currentType }}状态:</span>
            <span>{{ refundInfo.refund_status_text }}</span>
          </div>
          <div class="order-info-item">
            <span>申请时间:</span>
            <span>{{ refundInfo.create_time | unixToDate }}</span>
          </div>
          <div class="order-info-item">
            <span>服务类型:</span>
            <span>{{ refundInfo.refuse_type_text }}</span>
          </div>
          <div class="order-info-item">
            <span>退货方式:</span>
            <span>{{ refundInfo.refund_ship_type ? '快递至第三方卖家' : '上门取件' }}</span>
          </div>
          <h4 style="margin: 0" v-show="userInfo.third_shop === 'jingdong'">
            京东售后信息
          </h4>
          <div v-show="JDcode === 200">
            <div class="order-info-item">
              <span>京东售后单号:</span>
              <span>{{ JDinfo.requestId }}</span>
            </div>
            <div class="order-info-item">
              <span>京东订单号:</span>
              <span>{{ JDinfo.orderId }}</span>
            </div>
            <div class="order-info-item">
              <span>退款明细类型:</span>
              <span>{{ JDinfo.refundDetailType }}</span>
            </div>
            <div class="order-info-item">
              <span>退款金额:</span>
              <span>{{ JDinfo.refundAmount | unitPrice('¥') }}</span>
            </div>
            <div class="order-info-item">
              <span>财务号:</span>
              <span>{{ JDinfo.financeNo }}</span>
            </div>
            <div class="order-info-item">
              <span>确认时间:</span>
              <span>{{ JDinfo.confirmDate }}</span>
            </div>
          </div>
        </div>
        <!--退款/货信息-->
        <div class="refund-info-relations">
          <div class="order-info-item">
            <span>{{ currentType }}原因:</span>
            <span>{{ refundInfo.refund_reason }}</span>
          </div>
          <div class="order-info-item">
            <span>详细描述:</span>
            <span>{{ refundInfo.customer_remark || '无' }}</span>
          </div>
          <template v-if="type !== 'goods1'">
            <!-- <div class="order-info-item" v-if="!authOpera.allow_seller_ship">
              <span>退款渠道:</span>
              <span>{{ refundInfo.account_type_text || "无" }}</span>
            </div>
            <div class="order-info-item" v-if="!authOpera.allow_seller_ship">
              <span>退款账号:</span>
              <span>{{
                refundInfo.return_account ||
                refundInfo.bank_account_number ||
                "无"
              }}</span>
            </div> -->
            <div class="order-info-item">
              <span>退款金额:</span>
              <span>{{
      ($store.getters.shopInfo.shop_type === 1
        ? refundInfo.refund_price
        : refundMoney) | unitPrice('¥')
    }}</span>
            </div>
            <div class="order-info-item" v-if="refundPoint">
              <span>退还积分:</span>
              <el-input v-if="authOpera.allow_seller_approval" v-model="refundPoint"></el-input>
              <span v-if="!authOpera.allow_seller_approval">{{
      refundPoint
    }}</span>
            </div>
          </template>
          <div class="order-info-item" v-if="type !== 'money'">
            <span>是否寄回商品:</span>
            <span style="color: red">{{
      refundInfo.is_need_send ? '需要寄回' : '不需要寄回'
    }}</span>
          </div>
          <div class="order-info-item" v-else>
            <span>货物状态:</span>
            <span style="color: red">{{
        refundInfo.receiving_status ? '已收到货' : '未收到货'
      }}</span>
          </div>
          <template v-if="authOpera.allow_seller_ship || authOpera.allow_stock_in">
            <div class="order-info-item">
              <span>物流公司:</span>
              <span>{{ refundInfo.customer_logi_name || '无' }}</span>
            </div>
            <div class="order-info-item">
              <span>物流单号:</span>
              <span>{{ refundInfo.customer_ship_no || '无' }}</span>
            </div>
            <div class="order-info-item" v-if="refundInfo && refundInfo.member_address">
              <span>邮寄地址:</span>
              <span>{{
      refundInfo.member_address.province +
      refundInfo.member_address.city +
      refundInfo.member_address.county +
      refundInfo.member_address.addr
    }}</span>
            </div>
          </template>
          <div class="order-info-item order-info-remark">
            <span>{{ type == 'goods1' ? '备注' : '审核备注' }}:</span>
            <el-input v-if="authOpera.allow_seller_approval || authOpera.allow_seller_ship
      " placeholder="最多200字" :maxlength="200" type="textarea" v-model="remark"></el-input>
            <span v-if="!authOpera.allow_seller_approval &&
      !authOpera.allow_seller_ship
      ">{{
      refundInfo.seller_remark || refundInfo.refuse_reason || '无'
    }}</span>
          </div>
          <div class="order-info-item">
            <span style="vertical-align: top">图片:</span>
            <!-- <span style="position: relative; top: -2px">
              {{ refundInfo.refund_img ? "" : "无" }}
            </span> -->
            <div style="flex-wrap: wrap; width: 100%; align-content: flex-start;">
              <el-image v-for="(item, index) in refund_img" :key="index" style="width: 100px; height: 100px" :src="item"
                :preview-src-list="refund_img">
              </el-image>
              <!-- <img style="width: 80px; height: 80px;" v-for="(item, index) in refund_img" :key="index" :src="item" alt=""> -->
            </div>
            <!-- <a target="_blank" :href="refundInfo.refund_img">
              <img :src="refundInfo.refund_img" width="120px" />
            </a> -->
          </div>
          <div class="order-info-item" v-if="authOpera.allow_seller_ship">
            <span>发 货:</span>
            <el-button type="primary" size="mini" @click="fhBoxShow = true">
              发货
            </el-button>
            <el-button type="danger" size="mini" @click="handleRefundAuth1(0)">
              拒绝
            </el-button>
          </div>
          <!--审核-->
          <div class="order-info-item" v-if="authOpera.allow_seller_approval">
            <span>审 核:</span>
            <!-- :disabled="shop_type_bool" -->
            <el-button type="primary" size="mini" @click="handleRefundAuth(1)">通过
            </el-button>
            <el-button type="danger" size="mini" :disabled="shop_type_bool" @click="handleRefundAuth(0)">不通过
            </el-button>
          </div>
          <!--退款-->
          <div class="order-info-item" v-if="authOpera.allow_seller_refund">
            <span>退 款:</span>
            <el-button type="primary" size="mini" @click="handleRefund">退款完成
            </el-button>
          </div>
          <!--入库-->
          <!--  -->
          <!-- <div
            class="order-info-item"
            v-if="refundInfo.refund_status == 'SEND_BACK' && (authOpera.allow_stock_in || advancedForm.self_type == 2)"
          >
            <span>入 库:</span>
            <el-button type="primary" size="mini" @click="handleWareHousing"
              >确认入库</el-button
            >
          </div> -->
        </div>
      </div>
      <div style="text-align: left; padding: 8px 8px 0; color: red" v-show="JDcode === 1">
        {{ JDmessage }}
      </div>
      <!--退货商品信息-->
      <en-table-layout :tableData="refundGoodsData" class="pop-table">
        <template slot="table-columns">
          <el-table-column label="商品图片">
            <template slot-scope="scope">
              <img :src="scope.row.goods_image" class="goods-image" />
            </template>
          </el-table-column>
          <el-table-column label="商品名称" :show-overflow-tooltip="true" width="220">
            <template slot-scope="{row}">
              {{ row.goods_name ? row.goods_name.replaceAll('amp;', '').replaceAll(' ', '&nbsp;') : '' }}
            </template>
          </el-table-column>
          <el-table-column prop="price" label="单价">
            <template slot-scope="scope">
              <template v-if="$store.getters.shopInfo.shop_type !== 2">
                {{ scope.row.shop_price | unitPrice('￥') }}
                <!-- 店铺 -->
                <span v-if="scope.row.revise_exchange_point ||
      scope.row.revise_exchange_point === 0
      ">+{{ scope.row.revise_exchange_point }}积分</span>
              </template>
              <template v-if="$store.getters.shopInfo.shop_type === 2">
                <!-- 供应商 -->
                {{ scope.row.shop_supplier_price | unitPrice('￥') }}
                <span v-if="scope.row.exchange_point || scope.row.exchange_point === 0
      ">+{{ scope.row.exchange_point }}积分</span>
              </template>
            </template>
          </el-table-column>
          <el-table-column prop="ship_num" label="购买数量" />
          <el-table-column prop="return_num" :label="type === 'goods1' ? '换货数量' : '退货数量'" />
        </template>
      </en-table-layout>
    </el-dialog>

    <!--退款失败原因-->
    <el-dialog title="退款失败原因" :visible.sync="isShowRefundFailReason" width="17%">
      <div align="center">{{ refund_fail_reason }}</div>
    </el-dialog>
    <!-- 发货 -->
    <el-dialog title="订单发货" :visible.sync="fhBoxShow" width="70%" :before-close="handleClose">
      <div class="fhBox">
        <div class="row no-gutters">
          <div class="col"></div>
          <el-button type="primary" size="small" :disabled="!selectList.length" @click="submitFhing">确认发货</el-button>
        </div>
        <el-table border ref="multipleTable" :data="sku_list" style="width: 100%"
          @selection-change="handleSelectionChange"
          :header-cell-style="{ textAlign: 'center', backgroundColor: 'rgba(230, 236, 247, 1)' }">
          <el-table-column type="selection" width="45"></el-table-column>
          <el-table-column prop="sku_sn" label="商品编号" width="180px"></el-table-column>
          <el-table-column prop="name" label="商品名称以及规格" min-width="120px"></el-table-column>
          <el-table-column prop="num" label="商品数量" width="80px"></el-table-column>
          <el-table-column label="物流公司" width="180px">
            <template slot-scope="scope">
              <el-select size="mini" v-model="scope.row.logi_id" placeholder="请选择"
                @change="filterLogisticsData(scope.row)">
                <el-option v-for="item in logisticsData" :key="item.logi_id" :label="item.name"
                  :value="item.logi_id"></el-option>
              </el-select>
              <input type="text" style="display: none" v-model="scope.row.logi_name" />
            </template>
          </el-table-column>
          <el-table-column label="物流操作" width="200px">
            <template slot-scope="scope">
              <ul class="wlcz" style="list-style: none;">
                <li v-for="(item, index) in scope.row.delivery_list" :key="index">
                  <el-input size="mini" placeholder="请输入物流单号" v-model="item.delivery_no"></el-input>
                </li>
              </ul>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>
    <el-dialog title="视频" :visible.sync="videoding" width="80%">
      <video v-if="videoding" style="width:100%;height:500px" controls>
        <source :src="videoUrl" type="video/mp4" />
        <source :src="videoUrl" type="video/webm" />
      </video>
    </el-dialog>
  </div>
</template>

<script>
import * as API_refund from '@/api/refund';
import * as API_order from '@/api/order';
import * as API_Goods from '@/api/goods';
import { handleDownload } from '@/utils';
import { Foundation } from '@/../ui-utils';
import { CategoryPicker } from '@/components';
import { RegExp } from '@/../ui-utils';
import * as API_logistics from '@/api/expressCompany';
import EnTableLayout from '../../../../ui-components/TableLayout/src/main';

export default {
  name: 'refundList',
  components: {
    EnTableLayout,
    [CategoryPicker.name]: CategoryPicker,
  },
  data () {
    return {
      //视频播放弹窗
      videoUrl: '',
      videoding: false,
      refund_type: '1',
      refund_reason: '',
      importLoading: false,
      fhBoxShow: false,
      wuliuForm: {},
      logiList: [],
      wuliuRules: {
        // 货物状态
        seller_logi_id: [
          {
            required: true,
            message: '请选择物流！',
            trigger: 'change'
          },
        ],
        seller_ship_no: [
          {
            required: true,
            message: '请输入物流单号',
            trigger: 'blur'
          },
        ],
      },
      type: '',
      typeTitle: '',
      /** 列表loading状态 */
      loading: false,
      /** 列表参数 */
      params: {
        page_no: 1,
        page_size: 20,
      },
      /** 列表数据 */
      tableData: null,
      /** 列表分页数据 */
      pageData: {
        page_no: 1,
      },
      //来源应用数组
      mallTypeList: [
        { name: '全部', value: '' },
        { name: '现金商城', value: '2' },
        { name: '套餐卡商城', value: '3' },
        { name: '智能套餐卡商城', value: '4' },
        { name: '小程序商城', value: '6' },
      ],
      comParams: {
        cgrade: 2,
        page_no: 1,
        page_size: 20,
      },
      tableComData1: [],
      /** 高级搜索数据 */
      advancedForm: {
        //shop_member_unit_id: "",
        //order_sn: "",
        //sn: "",
        refuse_type: '',
        refund_status: '',
        //refund_time_range: [],
        self_type: 1,
        keyWord: 'sn',
        searchText: '',
        mall_type: ''
      },

      shop_type: this.$store.getters.shopInfo.shop_type,

      /** 当前退款/货 单号 */
      currentSn: '',

      /** 当前退款/货类型 文本*/
      currentType: '',

      /** 当前操作权限 */
      authOpera: {},

      /** 是否显示退款失败原因 */
      isShowRefundFailReason: false,

      /** 退款失败原因 */
      refund_fail_reason: '',

      /** 当前退款/货 信息 */
      refundInfo: {},
      refund_img: [],

      /** 退款金额编辑 */
      refundMoney: '',
      /** 退款金额 */
      refundMoney1: '',

      // 退款积分
      refundPoint: '',

      /** 退款商品列表数据 */
      refundGoodsData: [],

      /** 弹框是否显示 */
      goodsRefundshow: false,

      /** 审核备注 */
      remark: '',
      shop_type_bool: '',
      self_type_bool: this.$store.getters.shopInfo.shop_type === 3,
      userInfo: this.$store.state.user.user,

      JDinfo: {},
      JDcode: 1,
      JDmessage: '',
      parentLogin: 0, //0 代表企业登录   1代表企业跳转商家
      goodsRefundshow1: false,
      sku_list: [],
      selectList: [],
      /** 物流信息 */
      logisticsData: [],
      refund_sn: '',
      shop_id: this.$store.getters.shopInfo.shop_id,
      is_JdSupplier: this.$store.getters.shopInfo.shop_id === 66, // 京东供应商的shop_id为66
      hasOwnGoods: false
    };
  },
  async created () {
    const mallTypeList = await API_order.getShopByOrder();
    const doubledata = await API_order.getShopExtAuth();
    if (mallTypeList) {//有订单
      this.mallTypeList.push({ name: "双选商城", value: "5" })
    } else {//无订单判断大平台开关
      doubledata.double_choice_mall_open === "OPEN" ? this.mallTypeList.push({ name: "双选商城", value: "5" }) : null;
    }
    doubledata.drop_shipping_mall_open === "OPEN" ? this.mallTypeList.push({ name: "一件代发商城", value: "7" }) : null;
  },
  mounted () {
    const type = this.$route.query.type;
    if (type) {
      this.params['refund_status'] = 'APPLY'
      this.advancedForm.refund_status = 'APPLY'
    }
    API_Goods.getLoginType().then((res) => {
      this.parentLogin = res.parentLogin;
      this.GET_RefundOrder();
    });
    API_logistics.getExpressCompanyList({}).then((response) => {
      this.logiList = response;
    });
    if (this.shop_type === 3) {
      API_order.getEnterpriseISZiYou().then(res => {
        this.hasOwnGoods = res === 1
      })
    }
    this.getLogisticsCompanies()
  },
  watch: {},
  methods: {
    /**视频播放弹窗 */
    playVideo (src) {
      this.videoUrl = src;
      this.videoding = true;
    },
    setType (el) {
      console.log(parseInt(el.index) + 1);
      this.advancedForm.self_type = parseInt(el.index) + 1;
      this.GET_RefundOrder();
    },
    /** 获取物流公司信息列表 */
    getLogisticsCompanies () {
      API_logistics.getExpressCompanyList({}).then((res) => {
        this.logisticsData = res;
      });
    },
    handleClose () {
      this.fhBoxShow = false;
    },
    /** 分页大小发生改变 */
    handlePageSizeChange (size) {
      this.params.page_size = size;
      this.GET_RefundOrder();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange (page) {
      this.params.page_no = page;
      this.GET_RefundOrder();
    },

    /** 高级搜索事件触发 */
    advancedSearchEvent () {
      const params = {};
      if (this.advancedForm.searchText) {
        params[this.advancedForm.keyWord] = this.advancedForm.searchText;
      }

      this.GET_RefundOrder(params);
    },

    /** 四种对应操作 显示不同的弹框内容 */
    handleOperateRefund (row) {
      this.getRefundDetails(row);
    },

    /** 打开审核弹窗 */
    examineRefund (row) {
      API_refund.getRefundDetails(row.sn).then((response) => {
        this.goodsRefundshow1 = true
        this.refund_img = []
        this.refundInfo = response.refund
        this.refundMoney = response.refund.refund_shop_price
        if (this.refundInfo.refund_img) {
          this.refund_img = this.refundInfo.refund_img.split(',')
        }
      })
    },

    /** 审核操作 */
    handleRefund1 (sn) {
      if (this.refund_reason.length > 200) {
        this.$message.error('拒绝原因不得超过200个字符')
        this.goodsRefundshow1 = false;
      } else {
        const _params = {
          agree: this.refund_type,
          refund_shop_price: this.refundMoney,
          sn: sn,
          reason: this.refund_reason
        }
        console.log(_params)
        API_refund.refundAuth(sn, _params).then(() => {
          this.goodsRefundshow1 = false;
          this.GET_RefundOrder();
        });
      }
    },

    /** 发货操作 */
    deliverGoods (row) {
      this.refund_sn = row.sn
      this.fhBoxShow = true
      API_order.newGetOrderItemsList(row.order_sn, row.goods_id).then((response) => {
        if (response && response.length) {
          let sku_list = [...response];
          sku_list.forEach((item) => {
            item.delivery_list = [{}];
            item.logi_id = ''
          })
          this.sku_list = sku_list;
          console.log(this.sku_list)
        }
      });
    },

    /** 确认发货 */
    submitFhing () {
      for (let i = 0; i < this.selectList.length; i++) {
        let index = i;
        let item = this.selectList[i];
        let ship_num = 0;
        if (!item.logi_id) {
          this.$message.error('请选择物流公司');
          return false;
        }
        if (item.delivery_list) {
          for (let j = 0; j < item.delivery_list.length; j++) {
            let item1 = item.delivery_list[j];
            if (!item1.delivery_no) {
              this.$message.error('请输入物流单号');
              return false;
            }
          }
        }
      }
      this.$confirm('确认发货?', '提示', { type: 'warning' }).then(() => {
        let fhInfo = {
          sn: this.refund_sn,
        };
        this.selectList.forEach((item, index) => {
          item.delivery_list.forEach((item1, index1) => {
            fhInfo['seller_logi_id'] = item.logi_id
            fhInfo['seller_logi_name'] = item.logi_name
            fhInfo['seller_ship_no'] = item1.delivery_no
          });
        });

        API_refund.sellerShip(this.refund_sn, fhInfo).then(() => {
          this.$message('发货成功')
          this.goodsRefundshow = false;
          this.fhBoxShow = false;
          this.GET_RefundOrder();
        });
      });
    },

    handleSelectionChange (list) {
      this.selectList = list;
    },

    filterLogisticsData (row) {
      this.logisticsData.forEach((res) => {
        if (res.logi_id === row.logi_id) {
          row.logi_name = res.name;
        }
      });
    },

    /** 获取退款单列表数据 */
    GET_RefundOrder (params = {}) {
      this.loading = true;
      // let mall_type = ''
      // switch (this.$route.name) {
      //   case 'refundList':
      //     mall_type = 2
      //     break
      //   case 'shopRefundList':
      //     mall_type = 2
      //     break
      //   case 'comboRefundList':
      //     mall_type = 3
      //     break
      //   case 'aiComboRefundList':
      //     mall_type = 4
      //     break
      // }

      params = {
        ...this.params,
        ...params,
        refund_status: this.advancedForm.refund_status,
        refuse_type: this.advancedForm.refuse_type,
        self_type: this.advancedForm.self_type,
        mall_type: this.advancedForm.mall_type
      };

      let shopType;

      if (!params.searchText) {
        shopType = this.$store.getters.shopInfo.shop_type;
      }
      const setData = (resp) => {
        this.loading = false;
        this.pageData = {
          data: resp.data,
          page_no: resp.page_no,
          page_size: resp.page_size,
          data_total: resp.data_total,
        };
      }

      if (shopType === 1) {
        API_refund.getRefundList(params).then((response) => {
          setData(response);
        });
      } else if (shopType === 2) {
        API_refund.getSellerRefundList(params).then((response) => {
          setData(response);
        });
      } else {
        let refundApiFunc = 'enterpriseRefundsList';
        this.parentLogin === 1 && (refundApiFunc = 'getSellerRefundList');
        API_refund[refundApiFunc](params).then((response) => {
          setData(response);
        });
      }
    },

    /** 显示退款失败原因 */
    showRefundFailReason (row) {
      this.isShowRefundFailReason = false;
      this.isShowRefundFailReason = true;
      this.refund_fail_reason = row.refund_fail_reason;
    },

    /** 查看退款详情 */
    handleRefundDetail (row) {
      // 企业
      if (this.shop_type === 3) {
        this.$router.push({
          path: `/order/refund-detail/${row.sn}`,
          query: {
            self_type: this.advancedForm.self_type
          }
        })
      } else {
        this.$router.push({
          path: `/order/refund-detail/${row.sn}`
        })
      }
    },

    /** 查看退款/货单详细 */
    getRefundDetails (row) {
      console.log(row);
      this.currentType = row.refuse_type_text;
      this.currentSn = row.sn;
      this.authOpera = row.after_sale_operate_allowable;
      this.shop_type_bool =
        this.$store.getters.shopInfo.shop_type === 1 &&
        row.refuse_shop_status === 1;
      if (this.$store.getters.shopInfo.shop_type !== 2) {
        this.refundMoney = row.refund_shop_price;
        this.refundMoney1 = row.refund_shop_price;
        // this.refundPoint = row.refund_shop_point;
      } else {
        this.refundMoney = row.refund_supplier_price;
        this.refundMoney1 = row.refund_shop_price;
        // this.refundPoint = row.refund_point;
      }
      this.remark = '';
      if (this.userInfo.third_shop === 'jingdong') {
        API_order.getJDAfterSale(row.order_sn).then((res) => {
          console.log(res);
          this.JDcode = res.code;

          if (res.code === 200) {
            this.JDinfo = res.data[0];
          } else {
            this.JDmessage = res.message;
          }
        });
      }
      API_refund.getRefundDetails(row.sn).then((response) => {
        this.goodsRefundshow = true;
        this.refundInfo = response.refund;
        if (this.refundInfo.refund_img) {
          this.refund_img = this.refundInfo.refund_img.split(',')
        } else {
          this.refund_img = []
        }
        if (Array.isArray(response.refund_goods)) {
          this.refundGoodsData = response.refund_goods;
        }
        if (this.refundInfo.refuse_type === 'RETURN_MONEY') {
          this.type = 'money';
          this.typeTitle = '退款审核';
        } else if (this.refundInfo.refuse_type === 'RETURN_GOODS') {
          this.type = 'goods';
          this.typeTitle = '退货审核';
        } else if (this.refundInfo.refuse_type === 'EXCHANGE_GOODS') {
          this.type = 'goods1';
          this.typeTitle = '换货审核';
        }
      });
    },

    /** 卖家审核退款/货 */
    handleRefundAuth (agree) {
      if (this.type !== 'goods1' && !RegExp.money.test(this.refundMoney)) {
        this.$message.error('请输入正确的退款金额');
        return;
      }
      const _tip = agree === 1 ? '通过' : '不通过';
      if (!agree) {
        if (!this.remark) {
          this.$message.error('请输入审核备注');
          return;
        }
      }
      this.$confirm(`确认${_tip}审核么?`, '确认信息', { type: 'warning' }).then(
        () => {
          const _params = {
            agree: agree,
            refund_shop_price: this.refundMoney1,
            refund_point: this.refundPoint,
            reason: this.remark,
          };
          API_refund.refundAuth(this.currentSn, _params).then(() => {
            this.goodsRefundshow = false;
            this.GET_RefundOrder();
            // this.$message.success("审核成功！");
          });
        }
      );
    },

    /** 卖家审核退款/货 */
    handleRefundAuth1 (agree) {
      if (!agree) {
        if (!this.remark) {
          this.$message.error('请输入审核备注');
          return;
        }
      }
      this.$confirm(`确认拒绝发货么?`, '确认信息', { type: 'warning' }).then(
        () => {
          const _params = {
            remark: this.remark,
            sn: this.currentSn,
          };
          API_refund.sellerShipRefuse(this.currentSn, _params).then(() => {
            this.goodsRefundshow = false;
            this.GET_RefundOrder();
          });
        }
      );
    },
    /** 发货 */
    handleRefundAuth2 (agree) {
      this.$refs['wuliuForm'].validate((valid) => {
        if (valid) {
          const params = JSON.parse(JSON.stringify(this.wuliuForm));
          this.$confirm(`确认发货么?`, '确认信息', { type: 'warning' }).then(
            () => {
              let logi_name = '';
              this.logiList.forEach((item) => {
                if (item.logi_id == params.seller_logi_id) {
                  logi_name = item.name;
                }
              });
              console.log(params.seller_logi_id);
              console.log(typeof params.seller_logi_id);
              params.seller_logi_name = logi_name;
              params.sn = this.currentSn;
              API_refund.sellerShip(this.currentSn, params).then(() => {
                this.goodsRefundshow = false;
                this.fhBoxShow = false;
                this.GET_RefundOrder();
              });
            }
          );
        } else {
          this.$message.error('表单填写有误，请核对！');
          return false;
        }
      });
    },

    /** 卖家执行退款 */
    handleRefund () {
      this.$confirm(`确认退款?`, '确认信息', { type: 'warning' }).then(() => {
        const _params = {};
        API_refund.toRefund(this.currentSn, _params).then(() => {
          this.goodsRefundshow = false;
          this.GET_RefundOrder();
        });
      });
    },

    /** 卖家执行入库操作 */
    handleWareHousing (row) {
      this.$confirm(`请确认是否收到货物，否则会钱财两空`, '操作', {
        type: 'info',
      }).then(() => {
        console.log(row)
        API_refund.wareHousing(row.sn).then(() => {
          this.GET_RefundOrder();
        });
      });
    },
    //导出
    submitImport () {
      this.importLoading = true;
      let orderFunc = 'getRefundList';
      let filterVals = [
        'goods_name',
        'sn',
        'trade_sn',
        'mobile',
        'remark',
        'create_time',
        'refuse_type_text',
        'refund_status_text',
      ];
      let tHeaders = [
        '商品名称',
        '售后单号',
        '订单编号',
        '下单账号',
        '下单备注信息',
        '申请时间',
        '类型',
        '状态',
      ];
      if (this.$store.getters.shopInfo.shop_type === 3) {
        orderFunc = 'enterpriseRefundsList';
      }
      if (this.$store.getters.shopInfo.shop_type === 2) {
        orderFunc = 'getSellerRefundList';
        let filterVals = [
          'goods_name',
          'sn',
          'trade_sn',
          'mobile',
          'create_time',
          'refuse_type_text',
          'refund_status_text',
        ];
        let tHeaders = [
          '商品名称',
          '售后单号',
          '订单编号',
          '下单账号',
          '申请时间',
          '类型',
          '状态',
        ];
      }
      let params = { ...this.params };
      params.page_no = 1;
      params.page_size = 99999;
      params.self_type = this.advancedForm.self_type
      API_refund[orderFunc](params)
        .then((response) => {
          response.data.map((item, index) => {
            item.create_time = Foundation.unixToDate(
              item.create_time,
              'yyyy-MM-dd hh:mm:ss'
            );
          });
          handleDownload(response.data, tHeaders, filterVals, '退款订单');
          this.importLoading = false;
        })
        .catch((res) => {
          this.importLoading = false;
        });
    },
  },
};
</script>

<style lang="scss">
.refund-list {
  .el-tabs__header {
    margin-bottom: 0;
  }
}

.playVideo {
  cursor: pointer;
  width: 80px;
  height: 80px;
  position: relative;

  img {
    width: 80px;
    height: 80px;
  }

  span {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 80px;
    font-size: 20px;
  }
}
</style>
